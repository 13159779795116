
  import { Component, Vue } from 'vue-property-decorator';
  import { DELETE_BOOKMAKER, FETCH_BOOKMAKER_ITEM, UPDATE_BOOKMAKER } from '@/store/bookmakers';
  import BookmakerEdit from '@/components/BookmakerEdit.vue';

  @Component({
    components: { BookmakerEdit }
  })
  export default class BookmakersCreate extends Vue {
    beforeMount() {
      this.$store.dispatch(FETCH_BOOKMAKER_ITEM, this.$route.params.id);
    }

    public save() {
      (this.$refs.bookmakerEdit as any).$v.$touch();

      if ((this.$refs.bookmakerEdit as any).$v.$error) {
        return;
      }

      this.$store.dispatch(UPDATE_BOOKMAKER)
        .then(() => this.$router.push({ name: 'app.bookmakers.list' }));
    }

    public remove() {
      this.$store.dispatch(DELETE_BOOKMAKER)
        .then(() => this.$router.push({ name: 'app.bookmakers.list' }));
    }
  }
